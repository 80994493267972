import { INPUT, INPUT_TYPES } from '$gbusiness/enums';
import InputRowModel from '$gbusiness/models/inputRow';
import { input } from '$gbusiness/helpers';
import { currency, percentage } from '$gbusiness/helpers/util';
import { insertEmptyOption } from '$gbusiness/helpers/input';

export function getDiscountText(d) {
  return `${d.name} (${d.isFlat ? currency(d.amount) : percentage(d.amount)})`;
}

export const STORE_USERS_FORM = (users, discounts = []) => [
  {
    items: [
      {
        label: 'INPUT.LABEL.SALESMEN',
        value: 'userIds',
        input: INPUT.MULTI_SELECT,
        isMultiline: true,
        options: users.map((user) => ({
          labelText: `${user.firstName} ${user.lastName}`,
          value: user.userId,
        })),
        gridSize: 12,
      },
    ],
  },
];

export const STORE_REGION_FORM = (regions) => [
  {
    items: [
      {
        label: 'COMMON.REGION',
        value: 'regionId',
        input: INPUT.SELECT,
        options: regions.map((r) => ({
          labelText: r.name,
          value: r.id,
        })),
        gridSize: 6,
      },
    ],
  },
];

export const STORE_TERM_FORM = (terms, warehouses, storeLevels) => [
  {
    items: [
      {
        label: 'SCREEN.STORES.COLS.TERM',
        value: 'termId',
        input: INPUT.SELECT,
        options: insertEmptyOption(
          terms.map((t) => ({
            labelText: t.name,
            value: t.id,
          })),
          'None',
          '0',
        ),
        gridSize: 6,
      },
      ...(warehouses.length > 0
        ? [
            {
              label: 'SCREEN.STORES.COLS.WAREHOUSE',
              value: 'warehouseId',
              input: INPUT.SELECT,
              options: insertEmptyOption(
                warehouses.map((t) => ({
                  labelText: t.name,
                  value: t.id,
                })),
                'None',
                '0',
              ),
              gridSize: 6,
            },
          ]
        : []),
      ...(storeLevels.length > 0
        ? [
            {
              label: 'SCREEN.STORES.COLS.LEVEL',
              value: 'storeLevelId',
              input: INPUT.SELECT,
              options: insertEmptyOption(
                storeLevels.map((t) => ({
                  labelText: t.name,
                  value: t.id,
                })),
                'None',
                '0',
              ),
              gridSize: 6,
            },
          ]
        : []),
    ],
  },
];

export const STORE_DISCOUNT_FORM = (discounts, selected) => {
  const options = discounts.reduce(
    (acc, d) => {
      if (!selected.includes(d.id)) return acc;
      acc.push({
        labelText: getDiscountText(d),
        value: d.id,
      });
      return acc;
    },
    [{ labelText: 'None', value: '0' }],
  );
  return [
    {
      items: [
        {
          label: 'SCREEN.DISCOUNTS.TITLE',
          value: 'settings.discounts',
          input: INPUT.MULTI_SELECT,
          isMultiline: true,
          options: discounts.map((d) => ({
            labelText: getDiscountText(d),
            value: d.id,
          })),
          gridSize: 12,
        },
        {
          label: 'SCREEN.DISCOUNTS.DEFAULT_DISCOUNT',
          value: 'discountId',
          disableFastField: true,
          input: INPUT.SELECT,
          options,
        },
      ],
    },
  ];
};

export const STORE_FORM = (onBlur, emailAvailable, isEdit = false): Array<InputRowModel> => [
  {
    items: [
      {
        label: 'INPUT.LABEL.STORE_NAME',
        value: 'name',
        input: INPUT.TEXT,
        gridSize: 5,
        validation: [input.requiredValidation],
      },
      {
        label: 'SCREEN.STORES.COLS.ACCOUNTNO',
        value: 'code',
        input: INPUT.TEXT,
        gridSize: 4,
        // validation: [input.requiredValidation],
      },
      {
        ...INPUT_TYPES.PERCENT,
        label: 'INPUT.LABEL.TAX',
        value: 'settings.taxPercent',
        decimalPlaces: 4,
        gridSize: 3,
        validation: [input.requiredValidation],
      },
      {
        ...INPUT_TYPES.PHONE,
        gridSize: 4,
      },
      {
        ...INPUT_TYPES.EMAIL,
        value: 'username',
        label: 'SCREEN.STORES.COLS.DEFAULT_EMAIL',
        gridSize: 5,
        onBlur: onBlur ? onBlur : undefined,
        ...(onBlur
          ? {
              validation: [
                {
                  condition: () => !emailAvailable,
                  message: 'EMAIL_EXISTS',
                },
              ],
            }
          : {}),
      },
      {
        ...INPUT_TYPES.PASSWORD,
        validation: undefined,
        gridSize: 3,
        ...(!isEdit && { validation: [input.passwordValidation, input.requiredValidation] }),
      },
      {
        ...INPUT_TYPES.GOOGLE_ADDRESS,
        value: 'address',
        label: 'SCREEN.STORES.COLS.ADDRESS',
        validation: [input.placeValidation, input.requiredValidation],
        gridSize: 6,
      },
      {
        ...INPUT_TYPES.GOOGLE_ADDRESS,
        value: 'shippingAddress',
        label: 'SCREEN.STORES.COLS.SHIPPING_ADDRESS',
        validation: [input.placeValidation, input.requiredValidation],
        gridSize: 6,
      },
      {
        label: 'INPUT.LABEL.PRICING',
        value: 'settings.wholesale',
        input: INPUT.RADIO,
        isMultiline: true,
        options: [
          {
            label: 'PRICING.WHOLESALE',
            value: 1,
          },
          {
            label: 'PRICING.RETAIL',
            value: 0,
          },
        ],
        gridSize: 6,
      },
      {
        label: 'SCREEN.STORES.COLS.INVOICE_EMAIL',
        input: INPUT.TEXTAREA,
        rows: 3,
        gridSize: 6,
        placeholder: 'INPUT.PLACEHOLDER.INVOICE_EMAIL',
        value: 'settings.invoiceEmail',
      },
      {
        label: 'SCREEN.STORES.COLS.NOTE',
        input: INPUT.TEXTAREA,
        rows: 10,
        gridSize: 12,
        value: 'settings.note',
      },
      {
        label: 'INPUT.LABEL.EMAIL_CONFIRMATION',
        value: 'settings.emailConfirmation',
        input: INPUT.CHECKBOX,
      },
      {
        label: 'INPUT.LABEL.TEXT_CONFIRMATION',
        value: 'settings.textConfirmation',
        input: INPUT.CHECKBOX,
      },
    ],
  },
];

export default STORE_FORM;
