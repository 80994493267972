import { INPUT, INPUT_TYPES } from '$gbusiness/enums';
import InputRowModel from '$gbusiness/models/inputRow';
import { input } from '$gbusiness/helpers';
import { getCategoriesFromDepts } from '$fbusiness/models/category';
import { getTaxOptions } from '$fbusiness/models/tax';
import { insertEmptyOption } from '$gbusiness/helpers/input';
import { ItemPricing } from '$fbusiness/models/itemPricing';
import intl from '$gintl';
import { currency } from '$gbusiness/helpers/util';

export const ITEM_FORM = (taxes, vendors, factory, prices: ItemPricing[] = []) => {
  const enablePricingLevel = factory.settings?.storeLevel && !!factory?.storeLevels.length;
  return [
    {
      items: [
        {
          label: 'INPUT.LABEL.ITEM_NAME',
          value: 'name',
          input: INPUT.TEXT,
          validation: [input.requiredValidation],
        },
        {
          label: 'INPUT.LABEL.VENDOR',
          value: 'vendorId',
          input: INPUT.SELECT,
          options: insertEmptyOption(
            vendors.map((v) => ({
              labelText: v.name,
              value: v.id,
            })),
            '(No Vendor)',
            '0',
          ),
        },
        {
          label: 'INPUT.LABEL.DESCRIPTION',
          value: 'description',
          input: INPUT.TEXT,
          gridSize: 12,
        },
      ],
    },
    {
      items: [
        {
          label: 'INPUT.LABEL.BARCODE',
          value: 'barcode',
          input: INPUT.TEXT,
          gridSize: 4,
        },
        {
          label: 'INPUT.LABEL.SKU',
          value: 'sku',
          input: INPUT.TEXT,
          gridSize: 3,
        },
        {
          label: 'INPUT.LABEL.TAX',
          value: 'taxId',
          input: INPUT.SELECT,
          shrink: true,
          options: getTaxOptions(taxes),
          gridSize: 5,
        },
        {
          ...INPUT_TYPES.AMOUNT,
          label: 'INPUT.LABEL.RETAIL_PRICE',
          value: 'retailPrice',
          postfix: '',
          gridSize: 3,
          validation: [input.requiredValidation],
        },
        // {
        //   ...INPUT_TYPES.AMOUNT,
        //   label: 'INPUT.LABEL.WHOLESALE_PRICE',
        //   gridSize: 3,
        //   postfix: '',
        //   placeholder: 'INPUT.PLACEHOLDER.OPTIONAL',
        //   value: 'wholesalePrice',
        // },
        // {
        //   ...INPUT_TYPES.AMOUNT,
        //   label: 'INPUT.LABEL.RAW_COST',
        //   gridSize: 3,
        //   postfix: '',
        //   placeholder: 'INPUT.PLACEHOLDER.OPTIONAL',
        //   value: 'rawCost',
        // },
        {
          label: 'INPUT.LABEL.QUANTITY',
          value: 'quantity',
          gridSize: 3,
          input: INPUT.NUMBER,
        },
        ...(factory.settings?.inventory && factory.warehouses?.length > 0
          ? [
              {
                label: 'INPUT.LABEL.DEFAULT_WAREHOUSE',
                value: 'warehouseId',
                input: INPUT.SELECT,
                shrink: true,
                options: factory.warehouses.map((v) => ({
                  labelText: v.name,
                  value: v.id,
                })),
                gridSize: 4,
              },
            ]
          : []),
      ],
    },

    {
      lineSpace: 2,
      items: [
        {
          label: 'INPUT.LABEL.VISIBILITY',
          value: 'hide',
          input: INPUT.CHECKBOX,
        },
        ...(!!factory?.settings?.serial
          ? [
              {
                label: 'INPUT.LABEL.SERIAL_REQUIRED',
                value: 'settings.requireSerial',
                input: INPUT.CHECKBOX,
              },
            ]
          : []),
        ...(!!factory?.settings?.discounts
          ? [
              {
                label: 'INPUT.LABEL.DISABLE_DISCOUNT',
                value: 'settings.disableDiscount',
                input: INPUT.CHECKBOX,
                className: 'disable-discount',
              },
            ]
          : []),
      ],
    },
    ...(enablePricingLevel
      ? [
          {
            title: intl('SCREEN.ITEMS.PRICING_LEVEL'),
            lineSpace: 0,
            className: 'pricing-level',
            items: prices.map((price, i) => ({
              labelText: price.name,
              label: '',
              value: `pricing[${i}].price`,
              input: INPUT.CURRENCY,
              placeholder: currency(price.expectedPrice) || '',
              maskType: INPUT.MASKS.CURRENCY,
              gridSize: 3,
            })),
          },
        ]
      : []),
  ];
};

export const ITEM_DEPT_FORM = (departments, onDepartmentUncheck): Array<InputRowModel> => {
  return [
    {
      items: [
        {
          label: '',
          value: 'deptIds',
          input: INPUT.MULTI_SELECT,
          disableFastField: true,
          options: departments.map((d) => ({
            labelText: d.name,
            value: d.id,
          })),
          onChange: onDepartmentUncheck,
          position: 'standard',
          isMultiline: true,
          gridSize: 12,
          validation: [
            {
              condition: (v) => !v.length,
              message: 'REQUIRED',
            },
          ],
        },
      ],
    },
  ];
};

export const ITEM_CATEGORY_FORM = (categories, values: any = {}): Array<InputRowModel> => {
  const filteredCategories = getCategoriesFromDepts(categories, values.deptIds);
  return [
    ...(values?.deptIds.length
      ? [
          {
            items: [
              {
                label: '',
                value: 'categoryIds',
                position: 'standard',
                input: INPUT.MULTI_SELECT,
                disableFastField: true,
                options: filteredCategories.map((d) => ({
                  labelText: d.name,
                  value: d.id,
                })),
                isMultiline: true,
                gridSize: 12,
                validation: [
                  {
                    condition: (v) => !v.length,
                    message: 'REQUIRED',
                  },
                ],
              },
            ],
          },
        ]
      : []),
    // {
    //   items: [
    //     {
    //       ...INPUT_TYPES.IMAGE,
    //       gridSize: 12,
    //       size: 'contain',
    //       gridHeight: '200px',
    //     },
    //   ],
    // },
  ];
};

export const ITEM_PHOTO_FORM = [
  {
    items: [
      {
        ...INPUT_TYPES.IMAGE,
        cacheField: 'updatedAt',
        gridSize: 12,
        gridHeight: '200px',
        gridWidth: '200px',
      },
    ],
  },
];

export const groomCats = (categories, values) => {
  const filteredCats = getCategoriesFromDepts(categories, values.deptIds).map((c) => c.id);
  return values.categoryIds.filter((v) => filteredCats.includes(v));
};

export const E_ITEM_FORM = () => [
  {
    className: 'checks',
    items: [
      {
        label: 'SCREEN.ITEM.E_BROCHURE',
        value: 'ebrochure',
        input: INPUT.CHECKBOX,
        gridSize: 12,
      },
      {
        label: 'SCREEN.ITEM.PROMO',
        value: 'settings.promo',
        input: INPUT.CHECKBOX,
        gridSize: 12,
      },
      {
        label: 'SCREEN.ITEMS.COLS.PACKING',
        value: 'settings.packing',
        gridSize: 4,
        input: INPUT.TEXT,
      },
      {
        label: 'SCREEN.ITEMS.COLS.SKID',
        value: 'settings.skid',
        gridSize: 4,
        input: INPUT.TEXT,
      },
    ],
  },
  {
    items: [
      {
        label: 'SCREEN.ITEMS.COLS.LOTNO',
        value: 'settings.lotNo',
        gridSize: 4,
        input: INPUT.TEXT,
      },
      {
        label: 'SCREEN.ITEMS.COLS.BINNO',
        value: 'settings.binNo',
        gridSize: 4,
        input: INPUT.TEXT,
      },
      // {
      //   ...INPUT_TYPES.IMAGE,
      //   isHidden: vals => !vals.settings.promo,
      //   label: 'SCREEN.ITEM.PROMO_PHOTO',
      //   value: 'settings.promo_photo',
      //   cacheField: 'fileSize',
      //   gridSize: 12,
      //   gridHeight: '200px',
      //   gridWidth: '200px',
      // },
    ],
  },
];

export default ITEM_FORM;
